import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/PageWrapper';
import { HeroSimple } from '../components/HeroSimple';

import ContactForm from '../components/ContactForm';

const ContactpageTemplate = ({
    data: {
        datoCmsContactpage: {
            seo,
            title,            
        },
    },
    pageContext,
}) => (
    <PageWrapper
        pageData={pageContext}
        seoTitle={seo?.seoTitle}
        seoDescription={seo?.seoDescription}
        seoImage={seo?.seoImage?.seoImageUrl}
    >
        <div className='ContactPageNavBackground'></div>
        <div className='ContactPageWrapper'>
            <div>
            <h1>{title}</h1>
            </div>
            <ContactForm />
        </div>
    </PageWrapper>
);

export default ContactpageTemplate;

export const query = graphql`
    query ContactpageQuery($locale: String!) {
        datoCmsContactpage(locale: { eq: $locale }) {
            locale
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
            title
        }
    }
`;