import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Button from './OldButton';
import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { usePageLocale } from '../hooks/usePageLocale';
import { StructuredText } from 'react-datocms';
import { Navigator } from './Navigator';

import ReCAPTCHA from "react-google-recaptcha";

export default function ContactForm() {
    const data = useStaticQuery(graphql`
        query ContactQuery {
            allDatoCmsContactpage {
              contactpageNodes: nodes {
                locale
                firstname
                lastname
                company
                phone
                mail
                message
                privacy {
                  value
                  links {
                    ... on DatoCmsPrivacypage {
                      id: originalId
                      locale
                    }
                  }
                }
              }
            }
        }
    `)

const {
  allDatoCmsContactpage: { contactpageNodes },
} = data;

const { pageLocale } = usePageLocale();

const contactpageNodesMatch = contactpageNodes.filter(
  ({ locale }) => locale === pageLocale
);

const [
  {
    firstname: firstname,
    lastname: lastname,
    company: company,
    phone: phone,
    mail: mail,
    message: message,
    privacy: privacy,
  },
] = contactpageNodesMatch;

const [recaptchaResponse, setRecaptchaResponse] = useState('');

const handleRecaptchaChange = (response) => {
  setRecaptchaResponse(response);
};

const handleSubmit = (event) => {
  event.preventDefault();

  // Formulardaten extrahieren
  const formData = new FormData(event.target);
  const data = {};
  for (const [name, value] of formData.entries()) {
    data[name] = value;
  }

  // Validierung prüfen
  if (!data.firstname || !data.lastname || !data.company || !data.phone || !data.email || !data.text || !data.checkbox || !recaptchaResponse) {
    alert('Bitte füllen Sie alle erforderlichen Felder aus und bestätigen Sie das reCAPTCHA.');
    return;
  }

  // Daten an die serverlose Funktion senden
  formData.append('recaptchaResponse', recaptchaResponse);

  fetch('/api/ContactFormFunction', {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data); // Serverantwort verarbeiten
    })
    .catch((error) => {
      console.error(error);
    });

  // Formular zurücksetzen
  event.target.reset();
  setRecaptchaResponse('');
};

    return (
        <div className='ContactFormWrapper'>
            <div className='ContactFormInner'>
                <form onSubmit={handleSubmit} action="#">
                
                  <div className=''>
                    <label className='ContactFormLabel' htmlFor='firstname'>
                      {firstname}*
                    </label>
                    <input className='ContactFormInput' name="firstname" id="firstname" type="firstname" required/>
                  </div>

                  <div className=''>
                    <label className='ContactFormLabel' htmlFor='lastname'>
                      {lastname}*
                    </label>
                    <input className='ContactFormInput' name="lastname" id="lastname" type="lastname" required/>
                  </div>

                  <div className=''>
                    <label className='ContactFormLabel' htmlFor='company'>
                      {company}*
                    </label>
                    <input className='ContactFormInput' name="company" id="company" type="company" required/>
                  </div>

                  <div className=''>
                    <label className='ContactFormLabel' htmlFor='phone'>
                      {phone}*
                    </label>
                    <input className='ContactFormInput' name="phone" id="phone" type="phone" required/>
                  </div>

                  <div className=''>
                    <label className='ContactFormLabel' htmlFor='email'>
                      {mail}*
                    </label>
                    <input className='ContactFormInput' name="email" id="email" type="email" required/>
                  </div>

                  <div className=''>
                    <label className='ContactFormLabel' htmlFor='text' required>
                      {message}
                    </label>
                    <textarea className='ContactFormInput' name="text" id="text"  />
                  </div>

                  <div className=''>
                    <label className='ContactFormLabel' htmlFor='checkbox'>
                      <input className='ContactFormInputCheckbox' name="checkbox" id="checkbox" type="checkbox" required/>
                      <StructuredText
                        data={privacy}
                        renderLinkToRecord={({
                          record,
                          children,
                          transformedMeta,
                        }) => (
                          <Navigator {...transformedMeta} recordId={record.id}>
                            {children}
                          </Navigator>
                        )}
                      />
                    </label>
                  </div>
                  
                    <ReCAPTCHA
                      sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
                      onChange={handleRecaptchaChange}
                    />
                  

                  <div className='ContactFormSubmit'>
                    <Button type='submit' className={'btn_wht'}>
                      Abschicken  <ArrowLongRightIcon className="btn_wht_icon" />
                    </Button>
                  </div>

                </form>
            </div>
        </div>
    )
  };
