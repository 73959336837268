import React from 'react';
import { navigate } from 'gatsby';

const Button = ({ children, onClick, className }) => (
    
        <button
            onClick={()=>{navigate(onClick)}}
            className={className}
        >
        {children}
        </button>
    );
export default Button;